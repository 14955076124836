<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_scadenziario">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>Data</th>
                                        <th class='all'>Valuta</th>
                                        <th class='all' style="text-align:right;padding-right:5px">Importo</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>
                                            <div class="div_n">&nbsp;</div>
                                        </th>

                                        <th class='all col_footer'>Totale</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer_right' id="v_totale">0,00</th>


                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contclienti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetClienti" class="vclientisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetClienti" fixed style="position:fixed;">
                                Clienti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetClienti = !sheetClienti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchClienti">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredClienti" :key="item.companyid"
                                        @click="manageClickClienti(item)">

                                        <v-img alt="Clienti Logo" class="  " contain src="@/assets/custom_filter.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemCliente'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpagamenti">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetPagamenti">
                        <v-sheet class="text-center" height="430px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closePagamentiSheet' icon @click="sheetPagamenti = !sheetPagamenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_pagamenti">
                                Pagamenti
                            </div>

                            <v-list-item v-for="tile in allPayments" :key="tile.title"
                                @click="filterPagamento(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                        tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>





        <div class="contpopuppagamento" style="display:none">

            <template>
                <modal name="popupPagamento" :clickToClose="false" :width="300" :height="350">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Pagamento

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataPagamento" label="Data" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataPagamento" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>


                        <v-col cols="12" md="12">

                            <div id="d_importo_pag"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Importo</label>

                                            <vue-autonumeric v-model="importoPagamento" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" id="fldInpPagare"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-pagamento" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" />


                                <v-img alt="" class="btn-reset-pagamento" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-pagamento" contain
                                    src="@/assets/save_update_data-invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>











    </div>
</template>

<script>
import apiinvoice from "../utils/invoice/apiinvoice";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */

// import Vue from "vue";
// import VModal from 'vue-js-modal';


export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        // Vue.use(VModal, {
        //     dialog: true
        // });

        this.$root.$children[0].normalDiv1 = true;
        this.$root.$children[0].normalDiv2 = true;

        this.$root.$children[0].invoiceDiv1 = false;
        this.$root.$children[0].invoiceDiv2 = false;
        this.$root.$children[0].invoiceDiv3 = false;
        this.$root.$children[0].invoiceDiv4 = false;
        this.$root.$children[0].invoiceDiv3Agila = false;

        this.$root.$children[0].calcolatorDiv1 = false;

        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].curTitle = namesurname;
        this.$root.$children[0].invoiceTitle = "Scadenziario";

        this.isAgila = window.$cookies.get("is_agila");


        var pointerVue = this;

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");


        var type_fatt = window.$cookies.get("type_fatt_from");
        if (type_fatt == "Clienti") {
            window.t_fatt = "Cliente";
        }
        else {
            window.t_fatt = "Fornitore";
        }

        window.showPopupEdit = 1;

        // pointerVue.$root.$children[0].normalDiv1 = true;
        // pointerVue.$root.$children[0].normalDiv2 = true;

        // pointerVue.$root.$children[0].invoiceDiv1 = false;
        // pointerVue.$root.$children[0].invoiceDiv2 = false;
        // pointerVue.$root.$children[0].invoiceDiv3 = false;
        // pointerVue.$root.$children[0].invoiceDiv4 = false;

        // pointerVue.$root.$children[0].curTitle = "Scadenziario";

        window.hideSpinSynFattura = 0;

        window.id_fattura = pointerVue.$route.params.id;

        window.fatt_rimanente = 0;

        window.customer_name = "";


        // inizializzazione valori
        window.dateEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");
        window.dateEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

        window.dateScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");
        window.dateScadA = window.$cookies.get("sel_filter_inv_date_scad_to");


        pointerVue.$root.$children[0].valDateFrom = window.$cookies.get("sel_filter_inv_date_scad_from");
        pointerVue.$root.$children[0].valDateTo = window.$cookies.get("sel_filter_inv_date_scad_to");


        $(document).ready(
            function () {

                $("body").off();



            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



            $('body').on('click', '.selpagamento', function () {

                pointerVue.sheetPagamenti = true;

            });

            $('body').on('click', '.selaggiorna', function () {

                window.table.ajax.reload();

            });


            $('body').on('click', '.seldate', function (event) {
                console.log(event);

                //  pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");
                pointerVue.dataScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");

                //pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");
                pointerVue.dataEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");

                $(".contpopupdatefrom").show();


                pointerVue.selPag = 3;

                pointerVue.$modal.show('popupDateFrom');



            });


            $('body').on('click', '.btn-cancel-pagamento', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPagamento');

            });

            $('body').on('click', '.btn-reset-pagamento', function (event) {
                console.log(event);

                pointerVue.dataPagamento = null;
                pointerVue.importoPagamento = null;

                $("#fldInpPagare").val("");

            });


            $('body').on('click', '.btn-confirm-pagamento', function (event) {
                console.log(event);

                var errore = "";

                if (pointerVue.dataPagamento == null) {
                    errore = "Specificare la data";
                }

                if (errore == "") {

                    if ($("#fldInpPagare").val() == "") {
                        errore = "Specificare l'importo";
                    }
                }



                console.log("IMPORTO: ", pointerVue.importoPagamento);
                console.log("RIMANENTE: ", window.fatt_rimanente);

                if (errore == "") {

                    var f_rim = window.fatt_rimanente.toString().replace(".", "");
                    f_rim = f_rim.toString().replace(",", ".");

                    console.log("RIMANENTE 2: ", f_rim);



                    if (parseFloat(pointerVue.importoPagamento) > parseFloat(f_rim)) {

                        errore = "L'importo massimo che si può specificare è di € " + window.fatt_rimanente;


                    }



                }




                if (errore == "") {


                    pointerVue.saveData();



                }
                else {

                    pointerVue.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }





            });








            $('body').on('click', '.seldate2', function (event) {
                console.log(event);

                //  pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");
                pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");

                //pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");
                pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

                $(".contpopupdateto").show();


                pointerVue.selPag = 3;

                pointerVue.$modal.show('popupDateTo');



            });


            $('body').on('click', '.btn-cancel-date-to', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateTo');

            });

            $('body').on('click', '.btn-reset-date-to', function (event) {
                console.log(event);

                pointerVue.dataScadA = null;
                pointerVue.dataEmissA = null;


            });


            $('body').on('click', '.btn-confirm-date-to', function (event) {
                console.log(event);

                window.dateScadA = pointerVue.dataScadA;
                window.dateEmissA = pointerVue.dataEmissA;

                pointerVue.$modal.hide('popupDateTo');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                var val_data_scad_to = "";
                if (pointerVue.dataScadA != null) {
                    val_data_scad_to = pointerVue.dataScadA;
                }

                var val_data_emiss_to = "";
                if (pointerVue.dataEmissA != null) {
                    val_data_emiss_to = pointerVue.dataEmissA;
                }

                pointerVue.$root.$children[0].valDateTo = val_data_scad_to;

                window.$cookies.set("sel_filter_inv_date_scad_to", val_data_scad_to, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_to", val_data_emiss_to, "9y");


                console.log("SEL PAG: ", pointerVue.selPag);

                var v_filter = "";

                if (pointerVue.selPag == 2) {
                    v_filter = "S";
                }
                else if (pointerVue.selPag == 3) {
                    v_filter = "N";
                }
                else if (pointerVue.selPag == 4) {
                    v_filter = "P";
                }


                window.curPayment = v_filter;







                window.table.ajax.reload();


            });








        });

        setTimeout(() => {

            this.syncInfoFattura();

        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":



                    this.deleteRow();

                    break;

                case "btn_exit":
                    break;

                case "btn_add":


                    this.openEditPopup();

                    break;

                case "btn_edit":


                    break;

                case "btn_back":

                    var type_fatt = window.$cookies.get("type_fatt_from");

                    window.$cookies.set("is_from_scad", 1, "9y");

                    if (type_fatt == "Clienti") {

                        router.push({
                            path: "/fattureClienti"
                        });

                    }

                    if (type_fatt == "Fornitori") {

                        router.push({
                            path: "/fattureFornitori"
                        });

                    }



                    break;

                default:
                    break;

            }
        });


        this.setupButtonsGrid();

        window.start = 0;

        pointerVue.setupGrid();

        this.hideShowButtons(false);




        //this.timeOpenEditPopup();

    },


    components: {
        VueAutonumeric,
    },


    data: () => ({

        isAgila: 0,

        dataPagamento: null,
        importoPagamento: null,

        selPag: null,

        custName: "",

        dataScadA: null,
        dataScadDa: null,
        showPicker2: null,

        dataEmissA: null,
        dataEmissDa: null,
        showPicker3: null,
        showPicker4: null,

        allPayments: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_tutti"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Si',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_si"

            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'No',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_no"


            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Parziali',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_parziali"


            },


        ],


        sheetPagamenti: false,

        sheetClienti: false,
        searchClienti: "",

        clienti: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredClienti() {
            return _.orderBy(this.clienti.filter(item => {
                if (!this.searchClienti) return this.clienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },




    },

    methods: {


        openEditPopup: function () {

            $(".contpopuppagamento").show();

            var today = new Date();

            var year = today.getFullYear();
            var month = (today.getMonth() + 1);
            var day = today.getDate();
            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }

            var date = year + '-' + month + '-' + day;

            console.log("DATA: ", date);

            console.log("RIMANENTE: ", window.fatt_rimanente);


            this.dataPagamento = date;

            this.importoPagamento = window.fatt_rimanente;

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            this.$modal.show('popupPagamento');


        },

        saveData: async function () {

            var that = this;


            var v_token = window.$cookies.get("token");

            console.log("TOKEN: ", v_token);
            console.log("DATA: ", that.dataPagamento);
            console.log("IMPORTO: ", that.importoPagamento);
            console.log("ID FATTURA: ", window.id_fattura);



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apiinvoice.insPagamento(
                v_token,
                window.id_fattura,
                that.dataPagamento,
                that.importoPagamento
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from insPagamento", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;


                    that.$modal.hide('popupPagamento');


                    setTimeout(() => {

                        var type_fatt = window.$cookies.get("type_fatt_from");

                        window.$cookies.set("is_from_scad", 1, "9y");

                        if (type_fatt == "Clienti") {

                            if (this.isAgila == 0) {

                                router.push({
                                    path: "/fattureClienti"
                                });


                            }


                            if (this.isAgila == 1) {

                                router.push({
                                    path: "/fattureClientiAgila"
                                });


                            }



                        }

                        if (type_fatt == "Fornitori") {

                            router.push({
                                path: "/fattureFornitori"
                            });

                        }


                    }, 200);





                }, 300);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );








        },



        getFirstDayMonth: function () {

            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var elements = firstDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },


        getLastDayMonth: function () {

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },





        syncInfoFattura: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getInfoFattura(
                v_token,
                window.id_fattura
            ).then((res) => {

                that.$root.$children[0].showProgress = false;



                console.log("res from getInfoFattura", res);


                $("#nomeCust").html(res.data.customer);
                $("#numFatt").html(res.data.invoice_number);
                $("#totFatt").html(res.data.invoice_tot_fatt);

                var v_rim_fatt = "0,00";
                if (res.data.invoice_rim != null) {

                    if (res.data.invoice_rim != "") {

                        v_rim_fatt = res.data.invoice_rim;


                    }


                }

                window.fatt_rimanente = res.data.invoice_rim;

                console.log("RIM FATT: ", v_rim_fatt)

                $("#rimFatt").html(v_rim_fatt);


                $("#totPagFatt").html(res.data.tot_pag_fattura);

                // totPagFatt

                if (window.showPopupEdit == 1) {


                    window.showPopupEdit = 0;

                    setTimeout(() => {

                        that.openEditPopup();

                    }, 200);



                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        // syncAziendaFiltro: async function () {

        //     var that = this;

        //     var v_token = window.$cookies.get("token");
        //     var v_company = window.$cookies.get("sel_filter_azienda_fatture");

        //     that.showSpinner = true;
        //     that.$root.$children[0].showProgress = true;

        //     var response = await apiinvoice.getAziendaFiltro(
        //         v_token,
        //         v_company
        //     ).then((res) => {

        //         that.$root.$children[0].showProgress = false;
        //         console.log("res from getAziendaFiltro", res);


        //         that.$root.$children[0].nomeAziendaSelezionata = res.data.azienda;



        //     }).catch(err => {
        //         that.$root.$children[0].showProgress = false;
        //         console.log(err);
        //         var msg = err.response.data.Error;

        //         that.$swal({
        //             icon: "error",
        //             text: msg,
        //             showConfirmButton: false,
        //             timer: 8000
        //         });
        //         console.log("Errori", "Non è stato possibile caricare i dati");
        //         console.log("response", response);

        //     }

        //     );

        // },

        timeOpenEditPopup: function () {

            var that = this;


            setTimeout(() => {

                that.openEditPopup();

            }, 1000);


            // let time = 0;

            // var that = this;




            // console.log(time);



            // setTimeout(() => {

            //     that.showSpinner = true;
            //     that.$root.$children[0].showProgress = true;

            //     if (location.hostname == "localhost") {

            //         setTimeout(() => {

            //             that.openEditPopup();

            //         }, 1000);

            //     }

            //     if (location.hostname != "localhost") {

            //         setTimeout(() => {

            //             that.openEditPopup();

            //         }, 1000);

            //     }



            // }, 3500);



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                    link: "/settings",
                    id: "btn_add",
                    disabled: false,
                    title: "Aggiungi",
                    width: 30

                });



                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30


                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    link: "/confOrariChoice",
                    // class: "inverted",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                    width: 35

                });





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },

        setupGrid: function () {

           

            var pointerVue = this;





            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                //var v_company = window.$cookies.get("sel_filter_azienda_fatture");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'data',
                        orderable: false,
                        name: 'data',
                        width: '4%'
                    },

                    {
                        data: 'valuta',
                        orderable: false,
                        name: 'valuta',
                        width: '2%'
                    },

                    {
                        data: 'importo',
                        orderable: false,
                        name: 'importo',
                        width: '4%'
                    },



                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("goGetScadenziarioDettagliInvoice") + '?username=' + v_token + "&id_invoice=" + window.id_fattura,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curCustomer = window.curCustomer;
                            valori.curPayment = window.curPayment;

                            valori.dateScadDa = window.dateScadDa;
                            valori.dateScadA = window.dateScadA;

                            valori.dateEmissDa = window.dateEmissDa;
                            valori.dateEmissA = window.dateEmissA;




                            var valSearch = $("#myTable_filter input").val();

                            var search = {
                                "value": $("#myTable_filter input").val(),
                                "regex": false
                            };

                            if (valSearch.length > 0) {
                                valori.search = search;
                                valori.columns = window.columns;
                                console.log(window.columns);
                            }

                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: ''
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        // $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        // $(".spin").hide();

                        // setTimeout(() => {

                        //     pointerVue.showSpinner = false;
                        //     pointerVue.$root.$children[0].showProgress = false;


                        // }, 1000);





                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();



                        $('.p_importo').number(true, 2, ',', '.');

                        var totale = 0;
                        $(".p_importo").each(function () {
                            totale = totale + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });


                        $("#v_totale").text(totale.toString().replace(".", ","));
                        $('#v_totale').number(true, 2, ',', '.');



                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("goGetScadenziarioDettagliInvoice") + '?username=' + v_token + "&id_invoice=" + window.id_fattura,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div style="display:table">
                    
                        <div style="display:table-row">
                            <div style="display:table-cell;font-weight:bold;padding-top:3px;min-width:97px">` + window.t_fatt + `:</div>
                            <div style="display:table-cell;font-weight:bold;padding-top:3px" id="nomeCust"></div>
                        </div>
                        <div style="display:table-row">
                            <div style="display:table-cell;font-weight:bold;padding-top:3px;min-width:97px">Fatt.:</div>
                            <div style="display:table-cell;font-weight:bold;padding-top:3px" id="numFatt"></div>
                        </div>
                        <div style="display:table-row">
                            <div style="display:table-cell;font-weight:bold;padding-top:3px;min-width:97px">Tot.&nbsp&nbspFatt. (€):</div>
                            <div style="display:table-cell;font-weight:bold;padding-top:3px" id="totFatt"></div>
                        </div>

                        <div style="display:table-row">
                            <div style="display:table-cell;font-weight:bold;padding-top:3px;min-width:97px">Pag. Fatt. (€):</div>
                            <div style="display:table-cell;font-weight:bold;padding-top:3px" id="totPagFatt"></div>
                        </div>
                        <div style="display:table-row">
                            <div style="display:table-cell;font-weight:bold;padding-top:3px;padding-bottom:3px;min-width:97px">Rim. Fatt. (€):</div>
                            <div style="display:table-cell;font-weight:bold;padding-top:3px;padding-bottom:3px" id="rimFatt"></div>
                        </div>
                    
                    </div>


                </div>
            `);


                $('body').on('click', '.selcliente', function () {

                    console.log(("EVENTO CLICK SELCLIENTE"));

                    pointerVue.sheetClienti = true;

                });




                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_scadenziario tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            //$("tr").removeClass("active-row");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });


                $(".selcambiaclientifornitori").on("ionChange", function (opt) {
                    console.log(opt);



                    if (this.value == 2) {


                        router.push({
                            path: "/fattureFornitori"
                        });



                    }


                });



                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);


                        window.id_par_sel = item.id;


                    }

                    if (!checked) {

                        $(v_row).removeClass("active-row");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            link: "/confOrariChoice",
                            //class: "inverted",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                            width: 35

                        },

                    ];

                }


                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },



                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30

                        },

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            link: "/confOrariChoice",
                            //class: "inverted",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                            width: 35

                        },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },


        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apiinvoice.deletePagamenti(
                            v_token,
                            ids,
                            window.id_fattura
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deletePagamenti", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            window.hideSpinSynFattura = 1;

                            setTimeout(() => {

                                setTimeout(() => {

                                    that.syncInfoFattura();

                                }, 100);


                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.separator {
    min-height: 20px;
    float: left;
    border-right: 1px solid black;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}

.cont_scadenziario #myTable {
    margin-top: 99px;
    margin-right: 3px;
    width: 400px !important;
}

.cont_scadenziario table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_scadenziario table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_scadenziario th {
    padding-left: 5px !important;
}

.cont_scadenziario ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_scadenziario .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_scadenziario #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
}

.cont_scadenziario div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_scadenziario #myTable_wrapper {
    min-width: initial;
    min-width: 500px;
    max-width: 500px;
    width: 500px;
}


.cont_scadenziario .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_scadenziario .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}


.cont_scadenziario .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_scadenziario .checkall {
    width: 13px;
    height: 13px;
}

.cont_scadenziario .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_scadenziario .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
    width: 100% !important;
}

.cont_scadenziario .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}


.cont_scadenziario .cont-status {
    position: relative !important;
    overflow: hidden !important;
    width: 30px !important;
    height: 10px !important;
    margin-left: 22px !important;
}

.cont_scadenziario .status {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    top: -11px;
    right: -11px;
}

.cont_scadenziario .green {
    background: green;
}

.cont_scadenziario .yellow {
    background: #ecec06;
}

.cont_scadenziario .red {
    background: red;
}

.cont_scadenziario .lightblue {
    background: lightblue;
}

.cont_scadenziario .orange {
    background: orange;
}

.cont_scadenziario .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_scadenziario .text_right2 {
    width: 100% !important;
    text-align: right !important;
}


.cont_scadenziario .selcambiaclientifornitori {
    max-width: 30px !important;
    min-height: 30px !important;
    margin-left: -5px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    background-image: url(/invoiceweb/img/change_invoiceweb2.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_scadenziario .selcliente {
    max-width: 28px;
    min-height: 28px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_scadenziario .selanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_scadenziario .seleliminaanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_scadenziario .selscadenziario {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_scadenziario .selpagamento {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}







.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemCliente {
    margin-left: 15px;
}


.closePagamentiSheet {
    position: absolute;
    right: 0;
}

.c_title_pagamenti {
    text-align: left !important;
    margin-left: 44px !important;
}

.cont_scadenziario div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}

.cont_scadenziario .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.cont_scadenziario .cl_valuta {
    font-weight: bold;
    text-align: center
}

.cont_scadenziario .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_scadenziario .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.swal2-icon.swal2-warning {
    border-color: #0000b9;
    color: #0000b9;
}


@media screen and (max-width: 960px) {

    .cont_scadenziario .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_scadenziario #myTable {
        /* margin-top:54px; */
    }

    .cont_scadenziario #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_scadenziario .cont_buttons {
        height: 92px;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0px;
    }

    .cont_scadenziario .wrap_buttons {
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cont_scadenziario .text_title {
        background-color: white;
        color: black;
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 9px;
        font-size: 10px;
        min-width: 50px;
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
    }



}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_scadenziario .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_scadenziario #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_scadenziario #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }


    .cont_scadenziario .cl_num {
        margin-left: -3px !important;
    }

    .cont_scadenziario .cl_checkall {
        margin-left: 0px !important;
    }

    .cont_scadenziario .cont-status {
        display: inline-flex;
    }

    .cont_scadenziario .cl_valuta {
        display: inline;
    }

    .cont_scadenziario .text_right {
        display: inline;
    }

    .cont_scadenziario .img_centered {
        display: inline;
    }


    .cont_scadenziario .col_footer {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        color: white;
    }

    .cont_scadenziario .col_footer_right {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        text-align: right;
        color: white;
    }


}
</style>
